<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header" v-if="safe || bankAccount">
      {{ (safe || bankAccount).name }} Açılış Fişi Ekle
    </template>
    <template slot="body">
      <p>
        Gireceğiniz bakiye, {{ safe ? "kasanın" : "banka hesabının" }} yeni
        bakiyesi olarak kaydedilecek.
      </p>
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-text-field
              v-if="date"
              :label="$t('labels.date')"
              :value="date.toLocaleDateString()"
              readonly
              filled
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              v-model="formData.balance"
              label="Bakiye"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.max(formData.balance, 9999999.99),
                      rules.min(formData.balance, -9999999.99),
                    ]
              "
              hint="Alacaklı girmek için eksi, borçlu girmek için artı bakiye girebilirsiniz."
              :disabled="disabled"
            />
          </v-col>
          <v-col>
            <rs-textarea
              :label="$t('labels.description')"
              v-model="formData.description"
              :rules="disabled ? [] : [rules.required]"
              :filled="disabled"
              :readonly="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          @submit.prevent="handleFormSubmit"
          hide-submit
          :hide-save-close="disabled"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["cluster"]),
    date() {
      if (!this.cluster) {
        return null;
      }

      const date = this.cluster.legal_beginning_date
        ? new Date(this.cluster.legal_beginning_date)
        : null;

      if (!date) {
        return null;
      }

      return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    },
  },
  data() {
    return {
      id: null,
      disabled: false,
      bankAccount: null,
      safe: null,
      formData: {
        balance: 0.0,
        description: null,
      },
    };
  },
  methods: {
    show(id, bankOrSafe, disabled) {
      this.id = null;
      this.disabled = false;

      this.formData = {
        balance: 0.0,
        description: null,
      };

      if (bankOrSafe) {
        if (bankOrSafe.bankAccount) {
          this.bankAccount = bankOrSafe.bankAccount;
        } else if (bankOrSafe.safe) {
          this.safe = bankOrSafe.safe;
        } else {
          this.$toast.error("Beklenmeyen bir hata oluştu");
          return false;
        }
      } else {
        this.$toast.error("Beklenmeyen bir hata oluştu");
        return false;
      }

      if (disabled) {
        this.disabled = true;
      }

      if (id) {
        this.id = id;
        this.load();
      } else if (!this.date) {
        this.$toast.error("Mali başlangıç tarihi girilmemiş");
        return;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .query(`balance-activities/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.formData.balance = this.originalData.bank_safe_amount;
          document.getElementsByClassName("formatted-text")[0].innerHTML =
            this.numberToLocaleFormat(this.originalData.bank_safe_amount);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      let url;

      if (this.safe) {
        url = `safes/${this.safe.id}/first-balance`;
      } else if (this.bankAccount) {
        url = `bank-accounts/${this.bankAccount.id}/first-balance`;
      } else {
        return false;
      }

      if (this.id) {
        url += `/${this.id}`;
        this.$api
          .put(url, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.handleCancelClick();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(url, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.handleCancelClick();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
