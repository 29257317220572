<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Banka Hesap Tanımları"
            icon="mdi-bank-plus"
            :add-route="
              can('edit-bank-account')
                ? { name: 'definitions.bank-accounts.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-bank-account')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
          >
            <!-- <template slot="buttons:append">
              <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                @click="showFirstBalanceForm = true"
                :disabled="selectedItems.length !== 1"
              >
                Açılış Fişi Ekle
              </v-btn>
            </template> -->
          </rs-table-title-bar>

          <v-row dense v-if="can('edit-bank-online')">
            <v-col>
              <v-btn
                class="me-1 mb-1"
                color="error"
                outlined
                dark
                small
                @click="transfer('outcome')"
              >
                Para Çekme
                <v-icon small>mdi-bank-transfer-out</v-icon>
              </v-btn>

              <v-btn
                class="me-1 mb-1"
                color="success"
                outlined
                dark
                small
                @click="transfer('income')"
              >
                Para Yatırma
                <v-icon small>mdi-bank-transfer-in</v-icon>
              </v-btn>

              <v-btn
                class="me-1 mb-1"
                color="primary"
                outlined
                dark
                small
                @click="transfer('bank')"
              >
                Bankalar Arası Transfer
                <v-icon small>mdi-bank</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'definitions.bank-accounts.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'definitions.bank-accounts.show',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank="{ item }">
          {{ item.bank_name }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.can_used_for_sms="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.residents_can_see="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.can_collect_money="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.can_receive_bills="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.debt="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.due="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.activity_balance="{ item, value }">
          <rs-table-cell-balance
            colored
            :value="value"
            :title="new Date(item.activity_on).toLocaleString()"
            v-if="value !== null"
          />
          <span class="text-muted" v-else>Hareket yok</span>
        </template>
      </v-data-table>

      <FirstBalanceForm
        v-if="showFirstBalanceForm"
        @dismissed="showFirstBalanceForm = false"
        @saved="showFirstBalanceForm = false"
        :name="selectedItems[0].name"
      />

      <TransferForm
        ref="transferForm"
        :type="transfertype"
        @saved="handleFormSaved"
      />

      <TransferDetail ref="transferDetail" />
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions } from "@/view/mixins";
import FirstBalanceForm from "@/view/content/safes/FirstBalanceForm";
import TransferForm from "@/view/pages/activities/TransferForm";

export default {
  components: {
    FirstBalanceForm,
    TransferForm,
  },
  mixins: [filtersToURL, hasPermissions],
  computed: {
    ...mapGetters(["bankList", "bankAccountTypeList", "clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      transfertype: null,
      showFirstBalanceForm: false,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-bank-account"),
        },
        {
          text: this.$t("labels.bank_name"),
          value: "bank",
          searchable: "multiselect",
          sortable: true,
          options: () => this.bankList,
          itemText: "name",
        },
        {
          text: this.$t("labels.account_short_name"),
          value: "name",
          searchable: "text",
          sortable: true,
        },
        {
          text: this.$t("labels.debt"),
          value: "debt",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.due"),
          value: "due",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Entegrasyon Bakiye",
          value: "activity_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.can_collect_money"),
          value: "can_collect_money",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.can_receive_bills"),
          value: "can_receive_bills",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.can_used_for_sms"),
          value: "can_used_for_sms",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.residents_can_see"),
          value: "residents_can_see",
          searchable: "yesNo",
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          sortable: true,
          align: "center",
        },
      ],
    };
  },
  methods: {
    handleFormSaved() {
      this.loadList();
    },
    transfer(type) {
      if (!type) {
        return false;
      }

      if (type === "bank") {
        this.transfertype = "bank";
        this.$refs.transferForm.show();
      } else if (type === "income") {
        this.transfertype = "income";
        this.$refs.transferForm.show();
      } else if (type === "outcome") {
        this.transfertype = "outcome";
        this.$refs.transferForm.show();
      }
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.resetTable();
      this.list = [];
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );
      params.cluster_id = this.clusterId;
      this.isLoading = true;

      this.$api
        .query("bank-accounts", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "definitions.bank-accounts.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
  },
};
</script>
