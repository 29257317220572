<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      {{ setTitle }}
    </template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense v-if="type === 'safe'">
          <v-col sm="6">
            <rs-select-safe
              label="Çıkan Kasa"
              v-model="formData.from_safe_id"
              :rules="[rules.required]"
              ref="from_safe"
            />
          </v-col>
          <v-col sm="6">
            <rs-select-safe
              label="Giren Kasa"
              v-model="formData.to_safe_id"
              :rules="[rules.required]"
              ref="to_safe"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="type === 'bank'">
          <v-col sm="6">
            <rs-select-bank-account
              label="Çıkan Banka"
              v-model="formData.from_bank_account_id"
              :rules="[rules.required]"
              ref="from_bank"
            />
          </v-col>
          <v-col sm="6">
            <rs-select-bank-account
              label="Giren Banka"
              v-model="formData.to_bank_account_id"
              :rules="[rules.required]"
              ref="to_bank"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="type === 'income'">
          <v-col sm="6">
            <rs-select-safe
              label="Çıkan Kasa"
              v-model="formData.from_safe_id"
              :rules="[rules.required]"
              ref="from_safe"
            />
          </v-col>
          <v-col sm="6">
            <rs-select-bank-account
              label="Giren Banka"
              v-model="formData.to_bank_account_id"
              :rules="[rules.required]"
              ref="to_bank"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="type === 'outcome'">
          <v-col sm="6">
            <rs-select-bank-account
              label="Çıkan Banka"
              v-model="formData.from_bank_account_id"
              :rules="[rules.required]"
              ref="from_bank"
            />
          </v-col>
          <v-col sm="6">
            <rs-select-safe
              label="Giren Kasa"
              v-model="formData.to_safe_id"
              :rules="[rules.required]"
              ref="to_safe"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="6">
            <rs-datepicker
              label="Tarih"
              v-model="formData.action_on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, 9999999.99),
              ]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="10">
            <rs-textarea
              label="Açıklama"
              :rules="[
                isauto ? [] : rules.required,
                rules.maxLength(formData.description, 500),
              ]"
              v-model="formData.description"
              rows="3"
              no-resize
              :readonly="isauto"
              :filled="isauto"
            />
          </v-col>
          <v-col sm="2">
            <v-switch label="Otomatik Açıklama" v-model="isauto" />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          :hide-submit="true"
          @submit.prevent="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";
import hasForm from "@/view/mixins/hasForm";

export default {
  name: "TransferForm",
  mixins: [hasForm],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isauto: true,
    };
  },
  computed: {
    ...mapGetters(["cluster"]),
    setTitle() {
      let title = "";
      if (this.type === "safe") {
        title = "Kasaları Arası Transfer";
      } else if (this.type === "bank") {
        title = "Bankalar Arası Transfer";
      } else if (this.type === "outcome") {
        title = "Para Çekme İşlemi";
      } else if (this.type === "income") {
        title = "Para Yatırma İşlemi";
      }
      return title;
    },
  },
  methods: {
    show(formData) {
      this.formData = formData ? Object.assign({}, formData) : {};

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
      this.formData.action_on = new Date().toISOString().substr(0, 10);
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      if (this.isauto) {
        let type1 = "";
        let type2 = "";
        if (this.type === "safe") {
          type1 = this.$refs.from_safe.$el.innerText.split("\n")[1];
          type2 = this.$refs.to_safe.$el.innerText.split("\n")[1];
        } else if (this.type === "bank") {
          type1 = this.$refs.from_bank.$el.innerText.split("\n")[1];
          type2 = this.$refs.to_bank.$el.innerText.split("\n")[1];
        } else if (this.type === "outcome") {
          type1 = this.$refs.from_bank.$el.innerText.split("\n")[1];
          type2 = this.$refs.to_safe.$el.innerText.split("\n")[1];
        } else if (this.type === "income") {
          type1 = this.$refs.from_safe.$el.innerText.split("\n")[1];
          type2 = this.$refs.to_bank.$el.innerText.split("\n")[1];
        }
        this.formData.description = `${type1} -> ${type2} ${new Date(
          this.formData.action_on
        ).toLocaleDateString()} tarihinde ${this.formData.amount}₺ tutarında ${
          this.setTitle
        } gerçekleşmiştir.`;
      }

      const formData = Object.assign({}, this.formData);
      this.$api
        .post(`/income/transfers`, formData)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$nextTick(() => this.$emit("saved", response.data.data));
          if (event && event.closeOnSave) {
            this.hide();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
